import React, { Component } from 'react'

import Isvg from 'react-inlinesvg'
import Page from '../containers/page'
import { Link } from "react-router-dom";


import { Container, Row, Col } from 'reactstrap'

import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper'

import Search from '../components/search'

import notification from '../assets/svg/notification.svg'
import profile from '../assets/svg/profile-image.svg'
import moreIcon from '../assets/svg/more.svg'
import map from '../assets/svg/map.svg'
import chart from '../assets/svg/chart.svg';
import newsIcon from '../assets/svg/counter-news-icon.svg';
import commentIcon from '../assets/svg/counter-comment-icon.svg';
import stuffIcon from '../assets/svg/counter-stuff-icon.svg';
import arrowRight from '../assets/svg/arrow-right.svg'
import SwitchButton from '../components/forms/fields/toggle.js'
import { API_ENDPOINT } from '../constants.js'
import editIcon from "../assets/svg/newEditIcon.svg";
import garabageIcon from "../assets/svg/newGarbageIcon.svg";
import ReactPaginate from 'react-paginate';

var striptags = require('striptags')

class HomePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      promotedProjects: [],
      ...props.initialData,
      form: false,

      useState: false,
      bannerForm: false,
      params: {},
      areas: [],
      showStatistics: false,
      query: "",
      newsData: [],
      category: [],
      selectedCategory: "",
      selectedDate: "",
      endSelectedDate: "",
      totalNews: 0,
    }
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }
  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }
  }

  handleOptionChange(event) {
    this.setState({ value: event.target.value });
  }


  componentDidUpdate(prevProps) {
    if (prevProps[0].location.search != this.props[0].location.search) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.get()
        }
      )
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
    this.fetchCategories()
    this.get()
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState(
          {
            ...data,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }
  }

  toggle = () => {
    this.setState({
      form: !this.state.form,
    })
  }

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        }
        obj[name] = value
        this.setState({ params: obj })
      } else {
        let obj = this.state.params
        obj[name] = value
        this.setState({ params: obj })
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          {},
          name,
          value,
          restart
        )
      )
    }
  }

  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        }
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value
        }
        this.setState({ params: obj })
      } else {
        let obj = this.state.params
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value
        }
        this.setState({ params: obj })
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          {},
          fields,
          restart
        )
      )
    }
  }
  async fetchCategories() {
    try {
      const response = await fetch(`${API_ENDPOINT}/data/categoryName`, {
        method: "GET",
        headers: {
          Authorization:
            typeof localStorage !== "undefined"
              ? `Bearer ${localStorage.getItem("authToken")}`
              : null,
        },
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ category: data.kategorije })
      } else {
        console.error('Error fetching category: ', response.status)
      }
    } catch (error) {
      console.error("Error fetching usernames: ", error)
    }
  }

  handleDateChange = (event) => {
    this.setState({ selectedDate: event.target.value })
  };

  handleCategoryChange = (event) => {
    this.setState({ selectedCategory: event.target.value })
  };
  handleInputChange = (event) => {
    this.setState({ query: event.target.value });
  };
  handleEndDateChange = (event) => {
    this.setState({ endSelectedDate: event.target.value })
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { selectedDate, selectedCategory, query, endSelectedDate } = this.state

      const params = new URLSearchParams();


      if (query) {
        params.append('searchQuery', encodeURIComponent(query));
      }

      if (selectedCategory) {
        params.append('category', selectedCategory)
      }

      if (selectedDate) {
        params.append('date', selectedDate)
      }

      if (endSelectedDate) {
        params.append('endDate', endSelectedDate)
      }

      let apiUrl = `${API_ENDPOINT}/data/filterQuery?${params.toString()}`;

      const response = await fetch(
        // `${API_ENDPOINT}/data/filterQuery?searchQuery=${this.state.query}&category=${this.state.selectedCategory}&date=${this.state.selectedDate}`,
        apiUrl,
        {
          method: 'GET',
          headers: {
            Authorization:
              typeof localStorage !== 'undefined'
                ? `Bearer ${localStorage.getItem('authToken')}`
                : null,
          }
        }
      );
      if (response.ok) {
        const data = await response.json();
        this.setState({ newsData: data.newsData, totalNews: data.totalNews });
      } else {
        console.error('Error fetching news data: ', response.status)
        console.error('Error response: ', await response.text());
      }
    } catch (error) {
      console.error('Error fetching news data: ', error);
    }
  }
  confirmDelete = (news) => {
    const confirmDelete = window.confirm(
      `Da li ste sigurni da zelite obrisati ovu vijest "${news.title}"?`
    );

    if (confirmDelete) {
      this.deleteNews(news._id);
    }
  };
  render() {
    let params = getSearchParams(this.props[0].location.search, {
      entries: 10,
      page: 0,
    })
    const { category, selectedCategory, selectedDate, newsData, totalNews, endSelectedDate } = this.state

    const newsItems = newsData ?
      <table className='filetr-query-table'>
        <thead className='filter-query-table-head'>
          <tr>
            <th className="list-news-head-border">Vijesti</th>
            <th className="list-news-head-border">Datum objave</th>
            <th className="list-news-head-border">Dodao</th>
            <th className="list-news-head-border last">Akcija</th>
          </tr>
        </thead>
        <tbody className='filter-query-table-body'>
          {newsData.map((item) => (
            <tr key={item._id}>
              <td className='filter-query-table-body-image'>
                <img src={API_ENDPOINT + item.thumb360} alt='News Thumbnail' />
                <p>{item.title}</p>
              </td>
              <td>
                <p>
                  {item.date}, {item.time}
                </p>
              </td>
              <td>
                {item.adminUser}
              </td>
              <td className="filter-query-table-actions">
                <Link to={`/addNews/${item._id}`} className="list-table-padding">
                  <Isvg src={editIcon} />
                </Link>
                <Isvg className="list-table-delete"
                  src={garabageIcon}
                  onClick={() => this.confirmDelete(item)}
                />
              </td>
            </tr>
          ))
          }
        </tbody>
      </table>
      : null;

    return (

      <div className='page'>

        <Container className={this.props.acitveSidebar ? 'dashboard' : 'dashboard padd-change'}>
          <Row>
            <Col lg='9' sm='12' xs='12'>
              <div className='component filter-content' style={{ boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)' }}>
                <form onSubmit={this.handleSubmit}>
                  <h5 className='component-header'>Pronađi željenu vijest</h5>
                  <div className='form-group'>
                    <input value={this.state.query} placeholder='Pretraži bazu...' className='form-control' onChange={this.handleInputChange} />
                  </div>
                  <div className='form-group'>
                    <select name='options' value={selectedCategory} onChange={this.handleCategoryChange}>
                      <option value={''}>Izaberite kategoriju</option>
                      {/* <option value='421'>Izaberite kategoriju</option> */}
                      {this.state.category.map((cat) => (
                        <option key={cat.categoryId} value={cat.categoryId}>
                          {cat.categoryName}
                        </option>
                      ))}
                    </select>
                    <div className='form-group-date-and-button'>
                      <p>Od :</p>
                      <input type='date' value={selectedDate} onChange={this.handleDateChange}></input>
                      <p>Do :</p>
                      <input type='date' value={endSelectedDate} onChange={this.handleEndDateChange}></input>
                      <button type='submit'>
                        Pretraži
                        <Isvg src={arrowRight} />
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {/* <div className="counter-blocks">
                <div className="counter-block">
                  <div className="counter-block-content">
                    <h5>Ukupno vijesti</h5>
                    <h3>{this.state.totalNumberOfNews}</h3>
                  </div>
                  <div className="counter-block-icon">
                    <Isvg src={newsIcon} />
                  </div>
                </div>

                <div className="counter-block">
                  <div className="counter-block-content">
                    <h5>Ukupno komentara</h5>
                    <h3>{this.state.totalNumberOfComments}</h3>
                  </div>
                  <div className="counter-block-icon">
                    <Isvg src={commentIcon} />
                  </div>
                </div>

                <div className="counter-block">
                  <div className="counter-block-content">
                    <h5>Ukupno korisnika</h5>
                    <h3>{this.state.totalNumberOfAdmins}</h3>
                  </div>
                  <div className="counter-block-icon">
                    <Isvg src={stuffIcon} />
                  </div>
                </div>
              </div> */}
              {/* {this.props.uData.role == 'Administrator' ? 
              <Row>
                <div className='component popular-pages'>
                  <h5 className='component-header'>Najgledanije stranice</h5>
                  {this.state.mostViewed && this.state.mostViewed.map((item,idx)=>{
                   return(
                    <div><span className='page-name'>{item.title}</span>
                    <div>
                      {item.views} pregleda
                    </div><hr></hr></div>
                   ) 
                  })}
                </div>
                
              </Row>
                : ''} */}
            </Col>
            <Col lg='3' sm='12' xs='12' >
              {this.props.uData.role == 'Administrator' ?
                <div className='component customer-age' style={{ boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)' }}>
                  <h5 className='component-header'>Statistike za korisnika</h5>
                  <select value={this.state.value} onChange={this.handleOptionChange} name='options'>
                    {this.state.users && this.state.users.map((item, idx) => {
                      return (
                        <option value={idx}>{item.username}</option>
                      )
                    })}
                  </select>
                  {this.state.value ? <div className='statistics-home-page'>Ukupno vijesti: {this.state.users[this.state.value].newsCount}<br></br>
                    <br></br>
                    Ukupno vijesti u prethodnih mjesec dana: {this.state.users[this.state.value].newsCountm}<br></br>
                    <br></br>
                    Ukupno vijesti u poslednja 24h: {this.state.users[this.state.value].newsCount24}<br></br>
                    <br></br>
                    Ukupno vijesti u prethodnih 7 dana: {this.state.users[this.state.value].newsCount7d}<br></br>
                  </div> : ''}
                </div>
                : ""}


            </Col>
            <Col lg='12' sm='12' xs='12'>
              {newsItems}
              <p>Total News: {totalNews}</p>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Page(HomePage)
