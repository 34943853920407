import React, { Component } from "react";
import Page from "../containers/page";
import { Container, Row, Col, Input } from "reactstrap";
import Isvg from "react-inlinesvg";
import { Link } from "react-router-dom";
import DeleteModal from "../components/deleteModal";
import { API_ENDPOINT } from "../constants";

import notification from "../assets/svg/notification.svg";
import profile from "../assets/svg/profile-image.svg";
import moreIcon from "../assets/svg/more.svg";
import ReactPaginate from "react-paginate";

import editIcon from "../assets/svg/newEditIcon.svg";
import garabageIcon from "../assets/svg/newGarbageIcon.svg";
// import editIcon from "../assets/svg/edit.svg";
// import garabageIcon from "../assets/svg/garabage.svg";
import refresIcon from '../assets/svg/refresh.svg'
class NewsWithQuery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            perPage: 10,
            page: 0,
            newsData: [],
            total: 0,
            usernames: [],
            selectedUsername: "",
            query: "",
            searchTimeout: null,
            loading: false,
        };
    }
    async componentDidMount() {
        // this.newsCounter();
        this.fetchUsers();
        this.fetchNewsData();
    }

    //   async newsCounter() {
    //     try {
    //       const response = await fetch(`${API_ENDPOINT}/data/newsCount`, {
    //         method: "GET",
    //         headers: {
    //           Authorization:
    //             typeof localStorage !== "undefined"
    //               ? `Bearer ${localStorage.getItem("authToken")}`
    //               : null,
    //         },
    //       });
    //       if (response.ok) {
    //         const data = await response.json();
    //         this.setState({ total: data.total });
    //       } else {
    //         console.error("Error fetching total count: ", response.status);
    //       }
    //     } catch (error) {
    //       console.error("Error fetching total count: ", error);
    //     }
    //   }

    async fetchUsers() {
        try {
            const response = await fetch(`${API_ENDPOINT}/data/usernames`, {
                method: "GET",
                headers: {
                    Authorization:
                        typeof localStorage !== "undefined"
                            ? `Bearer ${localStorage.getItem("authToken")}`
                            : null,
                },
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ usernames: data.users });
            } else {
                console.error("Error fetching usernames: ", response.status);
            }
        } catch (error) {
            console.error("Error fetching usernames: ", error);
        }
    }

    // fetchNewsData = () => {
    //     // Fetch news data here using your API_ENDPOINT and query parameters
    //     fetch(`${API_ENDPOINT}/data/newsQuery?page=${this.state.page + 1}&perPage=${this.state.perPage}&createdBy=admin`, {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
    //         },
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             this.setState({ newsData: data });
    //         })
    //         .catch(error => {
    //             console.error('Error fetching news data', error);
    //         });
    // }
    fetchNewsData = async () => {
        const { page, perPage, selectedUsername, query } = this.state;

        // Construct the API query URL with the selected username filter
        let apiUrl = `${API_ENDPOINT}/data/newsQuery?page=${page + 1
            }&perPage=${perPage}`;

        if (selectedUsername) {
            apiUrl += `&adminUser=${selectedUsername.toString()}`;
        }
        if (query) {
            apiUrl += `&searchQuery=${encodeURIComponent(query)}`;
        }

        this.setState({ loading: true })
        // Fetch news data with the updated API query
        fetch(apiUrl, {
            method: "GET",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ newsData: data.newsData, total: data.total, loading: false });
            })
            .catch((error) => {
                console.error("Error fetching news data", error);
                this.setState({ newsData: [], total: 0, loading: false })
            });
    };

    deleteNews = async (newsId) => {
        try {
            const response = await fetch(
                `${API_ENDPOINT}/data/news/delete/${newsId}`,
                {
                    method: "DELETE",
                    headers: {
                        Authorization:
                            typeof localStorage !== "undefined"
                                ? `Bearer ${localStorage.getItem("authToken")}`
                                : null,
                    },
                }
            );

            if (response.ok) {
                // News deleted successfully, you may want to refresh the news data
                this.fetchNewsData();
            } else {
                console.error("Error deleting news: ", response.status);
            }
        } catch (error) {
            console.error("Error deleting news: ", error);
        }
    };

    handlePageClick = (data) => {
        this.setState({ page: data.selected }, () => {
            // Fetch news data when the page changes
            this.fetchNewsData();
        });
    };

    handleUsernameFilterChange = (event) => {
        const selectedUsername = event.target.value;
        this.setState({ selectedUsername, page: 0 }, () => {
            // Fetch news data with the selected username filter
            this.fetchNewsData();
        });
    };

    confirmDelete = (news) => {
        const confirmDelete = window.confirm(
            `Da li ste sigurni da zelite obrisati ovu vijest "${news.title}"?`
        );

        if (confirmDelete) {
            this.deleteNews(news._id);
        }
    };

    handleSearchInputChange = (event) => {
        const query = event.target.value;
        this.setState({ query }, () => {
            clearTimeout(this.state.searchTimeout);

            const searchTimeout = setTimeout(() => {
                this.fetchNewsData();
            }, 500);
            this.setState({ searchTimeout });
        })
    }

    render() {
        const { newsData } = this.state;
        const pageCount = this.state.total
            ? Math.ceil(this.state.total / this.state.perPage)
            : 1;
        return (
            <div className="page">
                <Container
                    className={
                        this.props.acitveSidebar ? "dashboard" : "dashboard padd-change"
                    }
                >
                    <Row>
                        <Col lg="12">
                            {/* <Search updateParams={this.updateParams} /> */}
                            <div className="panel">
                                <h5 className="component-header">Lista vijesti</h5>
                                <div className="main-table-header" >
                                    <button
                                        onClick={() => {
                                            fetch(API_ENDPOINT + "/data/refresh", {
                                                method: "POST",
                                                headers: {
                                                    "content-type": "application/json",
                                                    Authorization:
                                                        typeof localStorage !== "undefined"
                                                            ? `Bearer ${localStorage.getItem("authToken")}`
                                                            : null,
                                                },
                                            });
                                        }}
                                        className="add-news"
                                    >
                                        <Link>Osvježi vijesti</Link>
                                    </button>
                                    <button className="add-news">
                                        <Link to="/addNews">Dodaj vijest</Link>
                                    </button>
                                </div>
                                <div className="input-table-header">
                                    <span>
                                        <p>SORTIRAJ PO KORISINIKU :</p>
                                        {this.state.selectedUsername
                                            ? this.state.selectedUsername
                                            : "Svi korisnici"}
                                    </span>
                                    <Input
                                        type="select"
                                        onChange={this.handleUsernameFilterChange}
                                        value={this.state.selectedUsername}
                                    >
                                        <option value="">Svi korisnici</option>
                                        {this.state.usernames.map((username) => (
                                            <option
                                                key={username}
                                                value={username}
                                                selected={username === this.state.selectedUsername}
                                            >
                                                {username}
                                            </option>
                                        ))}
                                    </Input>
                                    <div className="news-query-by-name">
                                        <input type="text" placeholder="Pretrazi vijesti..." value={this.state.query} onChange={this.handleSearchInputChange} />
                                    </div>
                                </div>
                                {this.state.loading ? <div className="loader"></div> :
                                    <Col lg="12" className="table-container">
                                        <table className="listnews-table">
                                            <thead className="listnews-table-head">
                                                <tr>
                                                    <th className="list-news-head-border">Pozicija</th>
                                                    <th className="list-news-head-border">Vijesti</th>
                                                    <th className="list-news-head-border">Datum objave</th>
                                                    <th className="list-news-head-border">Dodao</th>
                                                    <th className="list-news-head-border last">Akcija</th>
                                                </tr>
                                            </thead>
                                            <tbody className="listnews-table-body">
                                                {newsData.map((newsItem) => (
                                                    <tr key={newsItem._id}>
                                                        <td className="listnews-table-body-position">{newsItem.position}</td>
                                                        <td className="listnews-table-body-news">
                                                            <img
                                                                className="list-image"
                                                                src={API_ENDPOINT + newsItem.images[0]}
                                                                alt="News Thumbnail"
                                                            />
                                                            <p>{newsItem.title}</p>
                                                        </td>
                                                        <td className="listnews-table-body-date">
                                                            <p>
                                                                {newsItem.date}, {newsItem.time}
                                                            </p>
                                                        </td>
                                                        <td className="listnews-table-body-addedby">
                                                            {newsItem.adminUser}
                                                        </td>
                                                        <td className="listnews-table-body-update">
                                                            <Link to={`/addNews/${newsItem._id}`} className="list-table-padding">
                                                                <Isvg src={editIcon} />
                                                            </Link>
                                                            <Isvg className="list-table-delete"
                                                                src={garabageIcon}
                                                                onClick={() => this.confirmDelete(newsItem)}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                }
                                <Col lg="12">
                                    <ReactPaginate
                                        previousLabel={"Nazad"}
                                        nextLabel={"Sledeca"}
                                        breakLabel={"..."}
                                        breakClassName={" disabled"}
                                        breakLinkClassName={" disabled"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination-news"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    />
                                </Col>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Page(NewsWithQuery);
