import React, { Component } from "react";
import Isvg from 'react-inlinesvg'
import Page from '../containers/page'

import logo from '../assets/infomilici/milici-logo.png'
import background from '../assets/infomilici/background.jpg'
import { API_ENDPOINT } from "../constants";


class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            username: '',
            password: '',
            rememberMe: false,
            error: '',
            formSubmitted: false,
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }

        // this.preventInspectElement();
    }

    login = (data) => {
        console.log("prolazi kroz login");
        fetch(API_ENDPOINT + '/auth/login', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.error) {
                    if (result.error === 'Wrong credentials.') {
                        this.setState({ error: 'User not found' })
                    } else {
                        this.setState({
                            error: result.error,
                        })
                    }
                } else {
                    localStorage.setItem('authToken', result.token)
                    this.props.verifyUser()
                    this.props[0].history.push('/')
                }
            })
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            formSubmitted: false,
        })
    }

    handleFormSubmit = async (e) => {
        e.preventDefault();

        const { username, password } = this.state;

        if (!username || !password) {
            this.setState({
                error: 'Please enter bot username and password.',
                formSubmitted: true,
            })
        }

        const data = {
            username: this.state.username,
            password: this.state.password,
            rememberMe: this.state.rememberMe || false,
        };

        try {
            await this.login(data);
        } catch (error) {
            console.error("An error occured during login:", error);
            this.setState({
                error: "An error occurred during login. Please try again. ",
            })
        }
    }

    preventInspectElement() {
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });

        document.addEventListener('keydown', (e) => {
            if (e.key === 'F12' || (e.key === 'U' && (e.ctrlKey || e.metaKey))) {
                e.preventDefault();
            }
        });

        window.addEventListener('devtoolschange', (e) => {
            if (e.detail.isOpen) {
                window.location.reload();
            }
        })
    }

    render() {

        const { formSubmitted, error } = this.state;

        return (
            <div className="login-page-new" style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}>
                <div className="login-page-form-container">
                    <div className="login-page-form">
                        <img src={logo} />
                        <p className={'login-page-greeting'}>Dobrodosli
                            nazad</p>
                        <form
                            className="login-page-form-data"
                            onSubmit={this.handleFormSubmit}>
                            <label
                                className={formSubmitted && !this.state.username ? 'login-page-error' : ''}>
                                Korisnicko ime
                                <input
                                    className={`placeholder-color ${formSubmitted && !this.state.username ? 'error-border' : ''}`}
                                    type="text"
                                    name="username"
                                    onChange={this.handleInputChange}
                                    placeholder={'Unesite korisnicko ime'} />
                                {formSubmitted && !this.state.username &&
                                    <p className={"login-page-error-message"}>Ime
                                        je obavezno</p>}
                            </label>
                            <label
                                className={formSubmitted && !this.state.username ? 'login-page-error' : ''}>
                                Lozinka
                                <input
                                    className={`placeholder-color ${formSubmitted && !this.state.password ? 'error-border' : ''}`}
                                    type="password"
                                    name="password"
                                    onChange={this.handleInputChange}
                                    placeholder={'Unesite lozinku'} />
                                {formSubmitted && !this.state.password &&
                                    <p className={"login-page-errorMessage"}>Lozinka
                                        je obavezna</p>}
                            </label>
                            <div
                                className="login-page-form-interactive">
                                <div
                                    className="login-page-form-check">
                                    {/* <label htmlFor="checkbox-login-page">Zapamti me</label>
                                    <input type="checkbox" id="checkbox-login-page" /> */}
                                </div>
                            </div>
                            <button
                                className="login-page-button"
                                type="submit">Uloguj se
                            </button>
                            {/* <input type="submit" /> */}
                        </form>
                        <p className="login-page-error">{this.state.error}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Page(Login)