import HomePage from './views/homePage'

// import Login from './views/loginPage'
import Login from './views/login'

import { API_ENDPOINT } from './constants'

import NewsWithQuery from './views/newsWithQuery'
import news from './views/news'
import addNews from './views/addNews';
import pages from './views/pages'
import addPages from './views/addPages';
import addCategory from './views/addCategory';
import category from './views/category';
import sortCategory from './views/sortCategory';
import Admin from './views/admin';
import addUser from './views/addUser';
import comments from './views/comments';
import Banners from './views/banners';
import AddBanner from './views/addBanner';

import Positions from './views/positions';
import AddPosition from './views/addPosition';

import dnevneIcon from './assets/infomilici/favicon.ico'

function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    }
  })
}

export const routes = [
  {
    path: '/',
    component: HomePage,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/statistics', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return result
        })
      },
      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/config/banner', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ banner, status }) => {
          if (status >= 200 && status < 300)
            return banner
        })
      },
    ],
  },
  {
    path: '/addCategory',
    component: addCategory,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/category', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              category: result,
            }
        })

      },
    ],
  },
  {
    path: '/addCategory/:id',
    component: addCategory,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [

    ],
    loadDataWithQuery: [

      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/category/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })

      },
      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/category', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              category: result,
            }
        })

      },
    ]
  },
  {
    path: '/category',
    component: category,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/category', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },

    ],
  },

  {
    path: '/sortCategory',
    component: sortCategory,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/sort/category', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result,
              total: result.total,
            }
        })

      },
    ],
  },

  {
    path: '/admin',
    component: Admin,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/user', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },

  {
    path: '/banners',
    component: Banners,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [],

    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/banners', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
              numberOfClicks: result.numberOfClicks
            }
        })

      },

    ]

  },
  {
    path: '/positions',
    component: Positions,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [],

    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/positions', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
              numberOfClicks: result.numberOfClicks
            }
        })

      },

    ]

  },
  {
    path: '/newsWithQuery',
    component: NewsWithQuery,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: []
  },
  {
    path: '/news',
    component: news,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [],

    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/news', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },

    ]

  },

  {
    path: '/addBanner',
    component: AddBanner,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [
      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/positions', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              positions: result,
            }
        })

      },
      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/banners/by/positions', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {

        })

      },


    ],
    loadDataWithQuery: [

    ]
  },
  {
    path: '/addPosition',
    component: AddPosition,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [

    ],
    loadDataWithQuery: [

    ]
  },
  {
    path: '/addNews',
    component: addNews,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [
      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/category', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              category: result,
            }
        })

      },
    ],
    loadDataWithQuery: [

    ]
  },


  {
    path: '/pages',
    component: pages,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [],

    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/pages', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },

    ]

  },
  {
    path: '/addPages',
    component: addPages,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [
      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/pages', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              pages: result,
            }
        })

      },
    ],
    loadDataWithQuery: [

    ]
  },

  {
    path: '/addPages/:id',
    component: addPages,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/pages/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })

      },
    ]
  },



  {
    path: '/addUser',
    component: addUser,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [],
    loadDataWithQuery: []
  },

  {
    path: '/addUser/:id',
    component: addUser,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/user/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })

      },
    ]
  },

  {
    path: '/addNews/:id',
    component: addNews,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [

    ],
    loadDataWithQuery: [

      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/news/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })

      },
      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/category', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              category: result,
            }
        })

      },
    ]
  },
  {
    path: '/addBanner/:id',
    component: AddBanner,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [

    ],
    loadDataWithQuery: [

      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/banners/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })

      },


      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/positions', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              positions: result,
            }
        })

      },

    ]
  },
  {
    path: '/addPosition/:id',
    component: AddPosition,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [

    ],
    loadDataWithQuery: [

      (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/positions/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })

      },

    ]
  },
  {
    path: '/comments',
    component: comments,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/comment', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/login',
    component: Login,
    redirectUser: '/',
    generateSeoTags: (data, lang) => {
      return {
        title: 'Info Milici - admin '.translate(lang),
        icon: dnevneIcon,
      }
    },
    loadData: [],
  },
]

export function generateRoutes(r) {
  let newRoutes = []
  for (let i = 0; i < r.length; i++) {
    newRoutes.push(r[i])

    /*newRoutes.push({
            ...r[i],
            path: linkTranslates.en[r[i].path]
        })*/
  }
  return newRoutes
}
