import React, { Component } from 'react'
import Page from '../containers/page'

import { Container, Row, Col, Input } from 'reactstrap'

import Isvg from 'react-inlinesvg'

import FormBuilder from '../components/forms/modalFormBuilder'
import { required } from '../components/forms/validation'
import Search from '../components/search'
import { Link } from 'react-router-dom'

import ListBuilder from '../components/listBuilder'
import DeleteModal from '../components/deleteModal'
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper'
import { API_ENDPOINT } from '../constants'

import editIcon from '../assets/svg/edit.svg'
import garabageIcon from '../assets/svg/garabage.svg'
import userIcon from '../assets/svg/user-icon.svg'
import notification from '../assets/svg/notification.svg'
import profile from '../assets/svg/profile-image.svg'
import moreIcon from '../assets/svg/more.svg'
import closeIcon from '../assets/svg/x.svg'
import rightIcon from '../assets/svg/arrow-right.svg'
import map from '../assets/svg/map.svg'
import chart from '../assets/svg/chart.svg'

class Users extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...props.initialData,
      form: false,
      useState: false,
      params: {
        entries: 10,
        page: 0,
      },
      items: [],
      areas: [],
      total: 0,
      loading: true,
      newsData: [],
    }
  }

  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }

    this.get()
  }
  handleOptionChange(event) {
    this.setState({ value: event.target.value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.search != this.props[0].location.search) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.get()
        }
      )
    }
  }

  updateSort = (field, type) => {
    if (this.state.useState) {
      let state = this.state.params
      state.sortField = field
      state.sortType = type
      this.setState({
        params: state,
      })
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          [
            { name: 'sortField', value: field },
            { name: 'sortType', value: type },
          ],
          false
        )
      )
    }
  }

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        }
        obj[name] = value
        this.setState({ params: obj })
      } else {
        let obj = this.state.params
        obj[name] = value
        this.setState({ params: obj })
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          name,
          value,
          restart
        )
      )
    }
  }

  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        }
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value
        }
        this.setState({ params: obj })
      } else {
        let obj = this.state.params
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value
        }
        this.setState({ params: obj })
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          fields,
          restart
        )
      )
    }
  }

  insertOrUpdate = (data) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        if (!this.state.form._id) {
          fetch(API_ENDPOINT + '/data/pages/new', {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              Authorization:
                typeof localStorage !== 'undefined'
                  ? `Bearer ${localStorage.getItem('authToken')}`
                  : null,
            },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((result) => {
              this.get()
              this.setState({
                form: null,
              })
            })
        } else {
          fetch(API_ENDPOINT + '/data/pages/' + this.state.form._id, {
            method: 'PUT',
            headers: {
              'content-type': 'application/json',
              Authorization:
                typeof localStorage !== 'undefined'
                  ? `Bearer ${localStorage.getItem('authToken')}`
                  : null,
            },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((result) => {
              this.get()
              this.setState({
                form: null,
              })
            })
        }
      }
    )
  }

  delete = (id) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        fetch(API_ENDPOINT + '/data/pages/delete/' + id, {
          method: 'DELETE',
          headers: {
            'content-type': 'application/json',
            Authorization:
              typeof localStorage !== 'undefined'
                ? `Bearer ${localStorage.getItem('authToken')}`
                : null,
          },
        })
          .then((res) => res.json())
          .then((result) => {
            this.get()
          })
      }
    )
  }

  render() {
    let params = {}
    if (this.state.useState) {
      params = this.state.params
    } else {
      params = getSearchParams(this.props[0].location.search, {
        entries: 10,
        page: 0,
      })
    }


    return (
      <div className='page'>
        <Container className={this.props.acitveSidebar ? 'dashboard' : 'dashboard padd-change'}>
          <Row>
            <Col lg='12'>
              <div className='panel'>
                <div className='main-table-header'>
                  <h5 className='component-header'>Lista Stranica</h5>
                  <button className='add-news'>
                    <Link to='/addPages'>
                      Dodaj Stranicu
                    </Link>
                  </button>

                </div>

                <ListBuilder
                  title='Lista lista vijesti'
                  loading={this.state.loading && false}
                  total={this.state.total}
                  showNumeration={false}
                  fields={[
                    {
                      type: 'text', class: 'labelbam', name: 'title', sortLabel: 'Po imenu', allowSort: true,
                    },
                  ]}
                  rawItems={this.state.items}
                  items={


                    this.state.items.map((item, idx) => {
                      return {
                        ...item,
                        source: <div>
                          <Isvg src={userIcon} className='name-icon' />
                          {item.source}
                        </div>,
                        name: item.name,
                        numberOfComments: this.props.uData.role == 'Administrator' ? item.numberOfComments : ''
                      }

                    })

                  }
                  actions={[
                    {
                      type: 'functionAction',
                      render: (item) => <Link to={
                        `/addPages/${item._id}`
                      }><Isvg src={editIcon} />
                      </Link>,
                    },
                    {
                      component: <Isvg src={garabageIcon} className='edit-icon' />,
                      onClick: (item) => this.setState({ deleteModal: item })
                    },
                  ]}
                  params={params}
                  sortField={params.sortField}
                  sortType={params.sortType}
                  updateSort={this.updateSort}
                  updateParams={this.updateParams}
                  updateMultipleParams={this.updateMultipleParams}
                ></ListBuilder>
                <DeleteModal
                  isOpen={this.state.deleteModal}
                  toggle={() => this.setState({ deleteModal: null })}
                  handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                >
                  Obrisi vijest <strong>{this.state.deleteModal && this.state.deleteModal.title}</strong>?
                </DeleteModal>

              </div>
            </Col>
          </Row>
        </Container>

      </div>
    )
  }
}

export default Page(Users)
